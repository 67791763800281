import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { OriginalUrlContext } from '../../utils/context';
import HtmlHead from '../../components/01_atoms/HtmlHead';
import Paragraphs from '../../components/02_molecules/Paragraphs';
import ModalGiftCard from '../../componentsGifts/ModalGiftCard';

import '../../styles/gifts/sass/theme.scss';

const GiftsHomePage = ({ entity: page }) => {
  const [cardId, setCardId] = useState(false);
  const originalUrl = useContext(OriginalUrlContext);
  const router = useRouter();
  useEffect(() => {
    if (originalUrl.split('?')[0].startsWith('/gift-card/')) {
      setCardId(originalUrl.split('?')[0].replace('/gift-card/', ''));
    }
  }, []);

  return (
    <div data-test="home-page-content">
      <HtmlHead metatags={page.metaTags} pageTitle={page.title} />
      {/* Render Gift Card with front page on background. */}
      {cardId && (
        <ModalGiftCard
          cardId={cardId}
          onModalClose={() => {
            setCardId('');
            // Remove Ecard params from URL by directing user to the home page.
            router.push('/');
          }}
        />
      )}
      <Paragraphs blocks={page.blocks} />
    </div>
  );
};

GiftsHomePage.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default GiftsHomePage;
