import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './index.module.scss';
import CloseIcon from '../../public/static/icons/close-rounded.svg';

const Popup = ({ children, isOpen = false, onRequestClose = () => {}, className = '' }) => {
  const onKeyDown = (e) => {
    // 'e.key' is empty in case of 'Space', using 'e.code' instead.
    if (e.code === 'Enter' || e.code === 'Space') {
      // Needs to prevent multiple firing of the action that may lead to side effects.
      e.preventDefault();
      e.stopPropagation();
      onRequestClose();
    }
  };

  return (
    <ReactModal
      isOpen={
        isOpen
        /* Boolean describing if the modal should be shown or not. */
      }
      // onAfterOpen={
      //   () => {}
      //   /* Function that will be run after the modal has opened. */
      // }
      // onAfterClose={
      //   () => {}
      //   /* Function that will be run after the modal has closed. */
      // }
      onRequestClose={
        onRequestClose
        /* Function that will be run when the modal is requested
           to be closed (either by clicking on overlay or pressing ESC).
           Note: It is not called if isOpen is changed by other means. */
      }
      // closeTimeoutMS={
      //   0
      //   /* Number indicating the milliseconds to wait before closing
      //      the modal. */
      // }
      // style={
      //   null
      //   /* Object indicating styles to be used for the modal.
      //      It has two keys, `overlay` and `content`.
      //      See the `Styles` section for more details. */
      // }
      // contentLabel={
      //   null
      //   /* String indicating how the content container should be announced
      //      to screenreaders */
      // }
      // portalClassName={
      //
      //   /* String className to be applied to the portal.
      //      See the `Styles` section for more details. */
      // }
      overlayClassName={
        `${styles['fade']} ${styles['modal-backdrop']} ${styles['in']}`
        /* String className to be applied to the overlay.
           See the `Styles` section for more details. */
      }
      // id={
      //   null
      //   /* String id to be applied to the content div. */
      // }
      className={
        `${styles['modal-dialog']} ${className}`

        /* String className to be applied to the modal content.
           See the `Styles` section for more details. */
      }
      bodyOpenClassName={
        styles['modal-open']
        /* String className to be applied to the modal ownerDocument.body
           (must be a constant string).
           This attribute when set as `null` doesn't add any class
           to document.body.
           See the `Styles` section for more details. */
      }
      // htmlOpenClassName={
      //   'ReactModal__Html--open'
      //   /* String className to be applied to the modal ownerDocument.html
      //      (must be a constant string).
      //      This attribute is `null` by default.
      //      See the `Styles` section for more details. */
      // }
      // ariaHideApp={
      //   true
      //   /* Boolean indicating if the appElement should be hidden */
      // }
      // shouldFocusAfterRender={
      //   true
      //   /* Boolean indicating if the modal should be focused after render. */
      // }
      // shouldCloseOnOverlayClick={
      //   true
      //   /* Boolean indicating if the overlay should close the modal */
      // }
      // shouldCloseOnEsc={
      //   true
      //   /* Boolean indicating if pressing the esc key should close the modal
      //      Note: By disabling the esc key from closing the modal
      //      you may introduce an accessibility issue. */
      // }
      // shouldReturnFocusAfterClose={
      //   true
      //   /* Boolean indicating if the modal should restore focus to the element
      //      that had focus prior to its display. */
      // }
      // role={
      //   'dialog'
      //   /* String indicating the role of the modal, allowing the 'dialog' role
      //      to be applied if desired.
      //      This attribute is `dialog` by default. */
      // }
      // preventScroll={
      //   false
      //   /* Boolean indicating if the modal should use the preventScroll flag when
      //      restoring focus to the element that had focus prior to its display. */
      // }
      // parentSelector={
      //   () => document.body
      //   /* Function that will be called to get the parent element
      //      that the modal will be attached to. */
      // }
      // aria={
      //   null
      //   // {
      //   //   labelledby: 'heading',
      //   //   describedby: 'full_description',
      //   // }
      //   /* Additional aria attributes (optional). */
      // }
      // data={
      //   null
      //   // { background: 'green' }
      //   /* Additional data attributes (optional). */
      // }
      // testId={
      //   ''
      //   /* String testId that renders a data-testid attribute in the DOM,
      //     useful for testing. */
      // }
      // overlayRef={
      //   null
      //   /* Overlay ref callback. */
      // }
      // contentRef={
      //   null
      //   /* Content ref callback. */
      // }
      overlayElement={
        (props, contentElement) => <div {...props}>{contentElement}</div>
        /* Custom Overlay element. */
      }
      // contentElement={
      //   (props, children) => <div {...props}>{children}</div>
      //   /* Custom Content element. */
      // }
      appElement={document.getElementById('__next')}
    >
      <div className={styles['modal-content']}>
        <button className={styles['close']} onClick={onRequestClose} onKeyDown={onKeyDown}>
          <CloseIcon aria-label="Close overlay" />
        </button>

        <div className={styles['modal-body']}>{children}</div>
      </div>
    </ReactModal>
  );
};

Popup.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
};

export default Popup;
